import React from 'react';
import { AlertTitle, Box, Button, Link, Alert } from '@mui/material';
import { format } from 'date-fns';
import { logPostHogEvent } from '@utils/index';
import useLocalStorage from '@hooks/useLocalStorage';
import trackUse from '@utils/trackUse';

const MailingListMessage = () => {
  const nextMailingDate = format(new Date(), 'qqq—yyyy');
  const [isMailingListMessageClosed, setIsMailingListMessageClosed] =
    useLocalStorage(`isMailingListMessageClosed—${nextMailingDate}`, false);

  if (isMailingListMessageClosed) {
    return null;
  }
  return (
    <Box marginTop="8px">
      <Alert
        severity="info"
        sx={{
          marginBottom: '8px'
        }}
      >
        <AlertTitle>Sign up for the mailing lists!</AlertTitle>
        Pssst! Make sure you{' '}
        <Link href="/email">
          <strong>sign up to the mailing lists</strong>
        </Link>{' '}
        to get the latest coupon deals mailed to you! We don&apos;t peddle any
        crap here! You&apos;ll find tons of deals on quality brands of household
        products you&apos;ll actually use year round.
        <Box display="flex" justifyContent="flex-end">
          <Button
            size="small"
            onClick={() => {
              setIsMailingListMessageClosed(true);
              trackUse({
                item: 'mailing-list-message-dismiss',
                value: 'closed',
                type: 'ACTION'
              });

              logPostHogEvent('mailing-list-message-dismiss', {
                value: 'closed',
                type: 'ACTION'
              });
            }}
          >
            Dismiss
          </Button>
        </Box>
      </Alert>
    </Box>
  );
};

export default MailingListMessage;
