/* eslint-disable react/no-array-index-key */
import React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
// import AutorenewIcon from '@mui/icons-material/Autorenew';
import trackUse from '@utils/trackUse';
import { DealPostType } from '@types';
import { logPostHogEvent } from '@utils/index';
import { SubscribeSaveExplanation } from '..';

const SubscribeSaveInfo = ({ maxSs }: { maxSs?: number }) => {
  return (
    <>
      <Box maxWidth="500px">
        <Typography sx={{ p: 2 }} component="h3">
          Subscribe & Save - Up to {maxSs || 5}% for this deal!
        </Typography>
        <Typography sx={{ p: 2 }}>
          Unlock up to 15% savings with Amazon Subscribe & Save! This feature
          lets you set up regular deliveries of your favorite products, saving
          you time and money. The more you subscribe, the more you save – enjoy
          up to 15% off on orders with at least 5 products.
        </Typography>
        <Typography sx={{ p: 2 }}>
          You&apos;re in control with easy cancellation anytime. For peace of
          mind, we suggest setting up deliveries every 6 months, giving you
          ample time to cancel before any potential price adjustments.
        </Typography>
      </Box>
      <Box maxWidth="600px">
        <SubscribeSaveExplanation />
      </Box>
    </>
  );
};

const SubscribeSaveChip = (props: DealPostType) => {
  const { ASIN, maxSs } = props;

  const [ssChipAnchorEl, setSSChipAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const ssChipOpen = Boolean(ssChipAnchorEl);

  const handleSSChipClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setSSChipAnchorEl(event.currentTarget);
    trackUse({
      item: 'open-subscribe-save-chip',
      value: ASIN,
      type: 'CLICK'
    });

    logPostHogEvent('open-subscribe-save-chip', {
      ASIN,
      type: 'CLICK'
    });
  };

  const handleSSChipClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSSChipAnchorEl(null);
  };

  const styles = {
    marginRight: '4px !important',
    backgroundColor: '#00796b !important',
    color: 'white !important',
    marginTop: '0.35em !important',
    fontSize: '11px'
  };
  return (
    <span key="ss-chip">
      <Chip
        sx={styles}
        aria-describedby={`ss_chip_${ASIN}`}
        size="small"
        onClick={handleSSChipClick}
        label={`S&S (${maxSs || 5}%)`}
      />
      <Popover
        id={`ss_chip_${ASIN}`}
        open={ssChipOpen}
        anchorEl={ssChipAnchorEl}
        onClose={handleSSChipClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SubscribeSaveInfo maxSs={maxSs} />
      </Popover>
    </span>
  );
};

export default SubscribeSaveChip;
